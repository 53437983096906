// third-party
import {UserOutlined, FileDoneOutlined, WindowsOutlined, LineChartOutlined,GiftOutlined} from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

// icons
const icons = {
    WindowsOutlined,
    UserOutlined,
    FileDoneOutlined,
    LineChartOutlined,
    GiftOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const reports = {
    id: 'all-reports',
    title: <FormattedMessage id="all-reports" />,
    icon: icons.FileDoneOutlined,
    type: 'group',
    children: [
        {
            id: 'lids-reports',
            title: <FormattedMessage id="lids-reports" />,
            type: 'item',
            url: '/report-lids',
            icon: icons.UserOutlined
        },
        {
            id: 'pacient-reports',
            title: <FormattedMessage id="pacient-reports" />,
            type: 'collapse',
            icon: icons.UserOutlined,
            children: [
                {
                    id: 'pacient-reports',
                    title: <FormattedMessage id="all-stats" />,
                    type: 'item',
                    icon: icons.LineChartOutlined,
                    url: '/report-stats'
                },
                {
                    id: 'first-reports',
                    title: <FormattedMessage id="first-reports" />,
                    type: 'item',
                    icon: icons.LineChartOutlined,
                    url: '/report-pacient'
                },
                {
                    id: 'bonus',
                    title: <FormattedMessage id="bonus" />,
                    type: 'item',
                    icon: icons.GiftOutlined,
                    url: '/bonus'
                },{
                    id: 'extra-bonus',
                    title: <FormattedMessage id="extra-bonus" />,
                    type: 'item',
                    icon: icons.GiftOutlined,
                    url: '/extra-bonus'
                }
            ]
        }
    ]
};

export default reports;

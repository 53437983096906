import {useEffect, useState} from 'react';
import dashboardApi from 'api/dashboardApi';
import useAuth from 'hooks/useAuth';
import moment from 'moment';
// material-ui
import {PlusOutlined} from "@ant-design/icons";
import {
    Box,
    Button,
    Grid,
    Dialog,
    MenuItem,
    Stack,
    TextField,
    Typography
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import AnalyticEcommerce from 'components/cards/statistics/AnalyticEcommerce';
import EcommerceWidget from 'components/cards/statistics/AnalyticEcommerceWidget';
import IncomeAreaChart from 'sections/dashboard/default/IncomeAreaChart';
import MonthlyBarChart from 'sections/dashboard/default/MonthlyBarChart';
import SalesChart from 'sections/dashboard/SalesChart';
import AddWidget from 'sections/apps/customer/AddWidget';
import { PopupTransition } from 'components/@extended/Transitions';
import DatePicker from 'sections/widget/data/Date';
import ApexPieChart from 'sections/charts/apexchart/DashboardApexPieChart';

// sales report status
const status = [
    {
        value: '6',
        label: 'Тиждень'
    },
    {
        value: '14',
        label: 'Місяць'
    },
    {
        value: '9',
        label: 'Останні 3 місяці'
    },
    {
        value: '11',
        label: 'Останні 6 місяців'
    },
    {
        value: '12',
        label: 'Рік'
    }
];

function getDate(params) {
    return moment(params).format('YYYY-MM-DD').valueOf()
}
// ==============================|| DASHBOARD - DEFAULT ||============================== //

const DashboardDefault = () => {
    const [value, setValue] = useState('14');
    const [slot, setSlot] = useState('month');
    const [patient, setPatient] = useState({lids:[],pay:[],paying:[],total:0});
    const [widget, setWidget] = useState({allTask:'0',countTask:'0',allCard:'0',countCard:'0',allPlan:'0',countPlan:'0',custom:[]});
    const [lids, setLids] = useState({monthTask:[],daysTask:[],monthCard:[],daysCard:[]});
    const [payment, setPayment] = useState({week:'0',month:'0',daysW:[],daysM:[],patient:[]});
    const { isLoggedIn, user } = useAuth();
    const [days, setDays] = useState("Рік");
    const [customWidget,setCustomWidget] = useState([]);
    const [add, setAdd] = useState(false);
    const [req, setReq] = useState();
    const [editDate, setEditDate] = useState();
    const [paid, setPaid] = useState([{year:'2023',month:'01',count:1}]);

    const handleAdd = (name) => {
        if(customWidget[name]){
            setEditDate(customWidget[name]);
        }
        setAdd(!add);
    };

    const getDates = (data,start,end) => {
        let search = 0, search_b = 0, count_p = 0;
        if(data.paid) {
            data.patient.forEach(field => {
                if (getDate(field.date_created) >= getDate(start) && getDate(field.date_created) <= getDate(end)) {
                    let payd = false;
                    data.paid.forEach(pay => {
                        if (field.patient_id == pay.patient_id) {
                            search_b += parseFloat(pay.amount);
                            if (!payd) {
                                count_p++;
                                payd = true
                            }
                        }
                    });
                    search++;
                }
            });
        }
        return {search,search_b,count_p};
    };

    const getPaid = (data,start,end) => {
        let search = [], paidItem = [];
        if(data.paid){
            data.paid.forEach(pay => {
                if(getDate(pay.date) >= getDate(start) && getDate(pay.date) <= getDate(end)){
                    let index = data.patient.find((item) => item.patient_id === pay.patient_id);
                    index && search.push({date: index.date_created, amount: pay.amount });
                }
            });
            search.forEach((field) => {
                let year = moment(field.date).year();
                let month = moment(field.date).month();
                if(paidItem.length > 0){
                    let index = paidItem.find((item) => item.year === year && item.month === month);
                    index ? index.count += parseFloat(field.amount) : paidItem.push({year:year,month:month,count:parseFloat(field.amount)});
                }else{
                    paidItem.push({year:year,month:month,count:parseFloat(field.amount)});
                }
            });
            setPaid(paidItem);
        }

    };

    const handleChange = (event) => {
        updatePatient(payment,event.target.value);
    };

    const changeWidget = (day,name) => {
        let line = day.start+'|'+day.end;
        setDays(name);
        setReq(line);
        getPlan(line);
        getPaid(payment,day.start,day.end);
    };
    const updateWidget = (names) => {
        let newWidget = customWidget;
        if(editDate){
            newWidget.map((field,key) => {
                if(editDate.title == field.title){
                    newWidget[key] = names
                }
            });
            setEditDate(null)
        }else{
            newWidget.push(names);
        }
        setCustomWidget(newWidget);
        getPlan(req)
    };
    const removeWidget = (id) => {
        let newWidget = customWidget;
        newWidget.splice(id, 1);
        setCustomWidget(newWidget);
        if(customWidget.length < 1){
            localStorage.setItem('dashboard', JSON.stringify(customWidget));
        }
        getPlan(req)
    };

    const updatePatient = (datas,event) => {
        const today = moment();
        let days = parseInt(event), newPatient = [], newLids = [], total = 0, totalPay = [];
        if(days == 6 || days == 14){
            for (let i = days; i > -1; i--) {
                let start = '', end = '';
                let sWeek = today.clone().endOf('isoWeek');
                let sMonth = today.clone().add(1, 'month').startOf('month').add(-2, 'day');

                if(days == 6){
                    start = sWeek.add(-i, 'day');
                    end = start.clone().add(1, 'day');
                }else if(days == 14){
                    start = sMonth.add(-i*2, 'day');
                    end = start.clone().add(2, 'day');
                }

                const count = getDates(datas,start,end);
                newLids.push(count.search);
                newPatient.push(count.search_b);
                totalPay.push(count.count_p);
                total += parseInt(count.search_b);
            }
        }else if(days == 9 || days == 11 || days == 12){
            for (let i = 0; i < days; i++) {
                let start = '', end = '';
                let sMonth = today.clone().add(-2, "month").startOf("month").add(i * 10, "day").add(-10, "day");
                let mMonth = today.clone().add(-5, "month").startOf("month").add(i * 15, "day").add(-15, "day");
                let yMonth = today.clone().startOf("year").add(i, "month").add(-1, "day");

                if (days == 9) {
                    start = sMonth.add(10, "day");
                    end = start.clone().add(10, "day");
                }else if(days == 11){
                    start = mMonth.add(15, "day");
                    end = start.clone().add(15, "day");
                }else if(days == 12){
                    start = yMonth.clone().startOf("month");
                    end = start.clone().endOf("month").add(1, "day");
                }

                const count = getDates(datas,start,end);
                newLids.push(count.search);
                newPatient.push(count.search_b);
                totalPay.push(count.count_p);
                total += parseInt(count.search_b);
            }
        }

        setPatient({lids:newLids,pay:newPatient,paying:totalPay,total:total});
        setValue(days);
    };

    const getPlan = async (day) => {
        let reqName = '';
        let newWidget = customWidget;
        const dashboard = window.localStorage.getItem('dashboard') ? JSON.parse(window.localStorage.getItem('dashboard')) : [];
        if(dashboard.length > 0 && customWidget.length < 1){
            setCustomWidget(Object.values(dashboard));
            newWidget = Object.values(dashboard);
        }else{
            localStorage.setItem('dashboard', JSON.stringify(customWidget));
        }
        if(newWidget.length > 0){
            newWidget.forEach((field,key) => {
                key < 1 ? reqName += field.name+'&'+field.title : reqName += '|'+field.name+'&'+field.title
            });
        }else{
            reqName = 'no'
        }
        const getForDate = await dashboardApi.getForDate(day,reqName);
        setWidget(getForDate);
    };

    useEffect(() => {
        const getRow = async () => {
            try {
                if(isLoggedIn && user.permissions.includes('dashboard') || user.role.includes('admin')) {
                    dashboardApi.getPayment().then(data => {
                            setPayment(data);
                            updatePatient(data,'6');
                            getPaid(data,
                                moment().clone().startOf('year').format('YYYY-MM-DD'),
                                moment().clone().endOf('year').format('YYYY-MM-DD'));
                    }).catch(err => {
                        alert(err)
                    });
                    const dmLids = await dashboardApi.getDM();
                    setLids(dmLids);
                    setSlot('week');
                    getPlan(365);
                }
            } catch (err) {
                alert(err.data.errors[0].msg)
            }
        };
        getRow()
    }, []);

    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            {/* row 1 */}
            <Grid item xs={12} sx={{ mb: -2.25 }}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h5">Робочий стіл</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={3}>
                <AnalyticEcommerce
                    title="Загальна кількість лідів"
                    count={widget.allTask.toLocaleString('uk-UA')}
                    color={widget.countTask < 1 ? 'warning' : 'primary'}
                    isLoss={widget.countTask < 1 ? true : false}
                    percentage={parseInt((widget.countTask/widget.allTask)*100)}
                    extra={widget.countTask < 1 ? parseInt(widget.countTask*-1).toLocaleString('uk-UA') : parseInt(widget.countTask).toLocaleString('uk-UA')}
                    sort={days}
                />
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={3}>
                <AnalyticEcommerce
                    title="Загальна кількість пацієнтів"
                    count={widget.allCard.toLocaleString('uk-UA')}
                    color={widget.countCard < 1 ? 'warning' : 'primary'}
                    isLoss={widget.countCard < 1 ? true : false}
                    percentage={parseInt((widget.countCard/widget.allCard)*100)}
                    extra={widget.countCard < 1 ? parseInt(widget.countCard*-1).toLocaleString('uk-UA') : parseInt(widget.countCard).toLocaleString('uk-UA')}
                    sort={days}
                />
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={3}>
                <AnalyticEcommerce
                    title="Загальна кількість продажів"
                    count={widget.allPlan.toLocaleString('uk-UA')}
                    color={widget.countPlan < 1 ? 'warning' : 'primary'}
                    isLoss={widget.countPlan < 1 ? true : false}
                    percentage={parseInt((widget.countPlan/widget.allPlan)*100)}
                    extra={widget.countPlan < 1 ? parseInt(widget.countPlan*-1).toLocaleString('uk-UA') : parseInt(widget.countPlan).toLocaleString('uk-UA')}
                    sort={days}
                />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={3}>
                <Grid item sx={{ display: 'flex', flexWrap: 'wrap' }} alignItems="center" justifyContent="space-between">
                    <DatePicker updateTable={changeWidget} all={'no'}/>
                    <Button
                        color="primary"
                        startIcon={<PlusOutlined />}
                        variant="dashed"
                        onClick={handleAdd}
                        sx={{ bgcolor: 'transparent !important' }}
                    >
                        Додати віджет
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mb: -2.25 }}>
                <Grid container rowSpacing={4.5} columnSpacing={2.75} alignItems="center">
                    {customWidget.length > 0 && (
                            widget.custom.map((field,key) => {
                                return (<EcommerceWidget req={req} key={key} keys={key} days={days} field={field} handelFilter={removeWidget} edit={handleAdd} />)
                            })
                        )
                    }
                </Grid>
            </Grid>
            <Grid item md={8} sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }} />

            {/* row 2 */}
            <Grid item xs={12} md={7} lg={8}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h5">Ліди VS Пацієнти</Typography>
                    </Grid>
                    <Grid item>
                        <Stack direction="row" alignItems="center" spacing={0}>
                            <Button
                                size="small"
                                onClick={() => setSlot('month')}
                                color={slot === 'month' ? 'primary' : 'secondary'}
                                variant={slot === 'month' ? 'outlined' : 'text'}
                            >
                                Місяць
                            </Button>
                            <Button
                                size="small"
                                onClick={() => setSlot('week')}
                                color={slot === 'week' ? 'primary' : 'secondary'}
                                variant={slot === 'week' ? 'outlined' : 'text'}
                            >
                                Тиждень
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
                <MainCard content={false} sx={{ mt: 1.5 }}>
                    <Box sx={{ pt: 1, pr: 2 }}>
                        <IncomeAreaChart dataLids={lids} slot={slot} />
                    </Box>
                </MainCard>
            </Grid>
            <Grid item xs={12} md={5} lg={4}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h5">{slot === 'month' ? 'Місячна' : 'Недільна'} статистика по грошам</Typography>
                    </Grid>
                    <Grid item />
                </Grid>
                <MainCard sx={{ mt: 2 }} content={false}>
                    <Box sx={{ p: 3, pb: 0 }}>
                        <Stack spacing={2}>
                            <Typography variant="h6" color="textSecondary">
                                За цей {slot === 'month' ? 'рік' : 'тиждень'}
                            </Typography>
                            <Typography variant="h3">{slot === 'month' ? payment.month.toLocaleString('uk-UA') : payment.week.toLocaleString('uk-UA')} грн</Typography>
                        </Stack>
                    </Box>
                    <MonthlyBarChart days={payment} slot={slot}/>
                </MainCard>
            </Grid>
            {/* row 4 */}
            <Grid item xs={12} md={7} lg={8}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h5">Нові пацієнти VS початок лікування</Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            id="standard-select-currency"
                            size="small"
                            select
                            value={value}
                            onChange={handleChange}
                            sx={{ '& .MuiInputBase-input': { py: 0.75, fontSize: '0.875rem' } }}
                        >
                            {status.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <SalesChart slot={parseInt(value)} patient={patient}/>

            </Grid>
            <Grid item xs={12} md={5} lg={4}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h5">Cтатистика оплати за {days}</Typography>
                    </Grid>
                    <Grid item />
                </Grid>
                <MainCard sx={{ mt: 2 }} content={false}>
                    <ApexPieChart data={paid}/>
                </MainCard>
            </Grid>
            {/*<Grid item xs={12} md={5} lg={4}>*/}
                {/*<Grid container alignItems="center" justifyContent="space-between">*/}
                    {/*<Grid item>*/}
                        {/*<Typography variant="h5">Transaction History</Typography>*/}
                    {/*</Grid>*/}
                    {/*<Grid item />*/}
                {/*</Grid>*/}
                {/*<MainCard sx={{ mt: 2 }} content={false}>*/}
                    {/*<List*/}
                        {/*component="nav"*/}
                        {/*sx={{*/}
                            {/*px: 0,*/}
                            {/*py: 0,*/}
                            {/*'& .MuiListItemButton-root': {*/}
                                {/*py: 1.5,*/}
                                {/*'& .MuiAvatar-root': avatarSX,*/}
                                {/*'& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }*/}
                            {/*}*/}
                        {/*}}*/}
                    {/*>*/}
                        {/*<ListItemButton divider>*/}
                            {/*<ListItemAvatar>*/}
                                {/*<Avatar*/}
                                    {/*sx={{*/}
                                        {/*color: 'success.main',*/}
                                        {/*bgcolor: 'success.lighter'*/}
                                    {/*}}*/}
                                {/*>*/}
                                    {/*<GiftOutlined />*/}
                                {/*</Avatar>*/}
                            {/*</ListItemAvatar>*/}
                            {/*<ListItemText primary={<Typography variant="subtitle1">Order #002434</Typography>} secondary="Today, 2:00 AM" />*/}
                            {/*<ListItemSecondaryAction>*/}
                                {/*<Stack alignItems="flex-end">*/}
                                    {/*<Typography variant="subtitle1" noWrap>*/}
                                        {/*+ $1,430*/}
                                    {/*</Typography>*/}
                                    {/*<Typography variant="h6" color="secondary" noWrap>*/}
                                        {/*78%*/}
                                    {/*</Typography>*/}
                                {/*</Stack>*/}
                            {/*</ListItemSecondaryAction>*/}
                        {/*</ListItemButton>*/}
                        {/*<ListItemButton divider>*/}
                            {/*<ListItemAvatar>*/}
                                {/*<Avatar*/}
                                    {/*sx={{*/}
                                        {/*color: 'primary.main',*/}
                                        {/*bgcolor: 'primary.lighter'*/}
                                    {/*}}*/}
                                {/*>*/}
                                    {/*<MessageOutlined />*/}
                                {/*</Avatar>*/}
                            {/*</ListItemAvatar>*/}
                            {/*<ListItemText primary={<Typography variant="subtitle1">Order #984947</Typography>} secondary="5 August, 1:45 PM" />*/}
                            {/*<ListItemSecondaryAction>*/}
                                {/*<Stack alignItems="flex-end">*/}
                                    {/*<Typography variant="subtitle1" noWrap>*/}
                                        {/*+ $302*/}
                                    {/*</Typography>*/}
                                    {/*<Typography variant="h6" color="secondary" noWrap>*/}
                                        {/*8%*/}
                                    {/*</Typography>*/}
                                {/*</Stack>*/}
                            {/*</ListItemSecondaryAction>*/}
                        {/*</ListItemButton>*/}
                        {/*<ListItemButton>*/}
                            {/*<ListItemAvatar>*/}
                                {/*<Avatar*/}
                                    {/*sx={{*/}
                                        {/*color: 'error.main',*/}
                                        {/*bgcolor: 'error.lighter'*/}
                                    {/*}}*/}
                                {/*>*/}
                                    {/*<SettingOutlined />*/}
                                {/*</Avatar>*/}
                            {/*</ListItemAvatar>*/}
                            {/*<ListItemText primary={<Typography variant="subtitle1">Order #988784</Typography>} secondary="7 hours ago" />*/}
                            {/*<ListItemSecondaryAction>*/}
                                {/*<Stack alignItems="flex-end">*/}
                                    {/*<Typography variant="subtitle1" noWrap>*/}
                                        {/*+ $682*/}
                                    {/*</Typography>*/}
                                    {/*<Typography variant="h6" color="secondary" noWrap>*/}
                                        {/*16%*/}
                                    {/*</Typography>*/}
                                {/*</Stack>*/}
                            {/*</ListItemSecondaryAction>*/}
                        {/*</ListItemButton>*/}
                    {/*</List>*/}
                {/*</MainCard>*/}
                {/*<MainCard sx={{ mt: 2 }}>*/}
                    {/*<Stack spacing={3}>*/}
                        {/*<Grid container justifyContent="space-between" alignItems="center">*/}
                            {/*<Grid item>*/}
                                {/*<Stack>*/}
                                    {/*<Typography variant="h5" noWrap>*/}
                                        {/*Help & Support Chat*/}
                                    {/*</Typography>*/}
                                    {/*<Typography variant="caption" color="secondary" noWrap>*/}
                                        {/*Typical replay within 5 min*/}
                                    {/*</Typography>*/}
                                {/*</Stack>*/}
                            {/*</Grid>*/}
                            {/*<Grid item>*/}
                                {/*<AvatarGroup sx={{ '& .MuiAvatar-root': { width: 32, height: 32 } }}>*/}
                                    {/*<Avatar alt="Remy Sharp" src={avatar1} />*/}
                                    {/*<Avatar alt="Travis Howard" src={avatar2} />*/}
                                    {/*<Avatar alt="Cindy Baker" src={avatar3} />*/}
                                    {/*<Avatar alt="Agnes Walker" src={avatar4} />*/}
                                {/*</AvatarGroup>*/}
                            {/*</Grid>*/}
                        {/*</Grid>*/}
                        {/*<Button size="small" variant="contained" sx={{ textTransform: 'capitalize' }}>*/}
                            {/*Need Help?*/}
                        {/*</Button>*/}
                    {/*</Stack>*/}
                {/*</MainCard>*/}
            {/*</Grid>*/}

            {/* row 3 */}
            {/*<Grid item xs={12} md={7} lg={8}>*/}
                {/*<Grid container alignItems="center" justifyContent="space-between">*/}
                    {/*<Grid item>*/}
                        {/*<Typography variant="h5">Recent Orders</Typography>*/}
                    {/*</Grid>*/}
                    {/*<Grid item />*/}
                {/*</Grid>*/}
                {/*<MainCard sx={{ mt: 2 }} content={false}>*/}
                    {/*<OrdersTable />*/}
                {/*</MainCard>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12} md={5} lg={4}>*/}
                {/*<Grid container alignItems="center" justifyContent="space-between">*/}
                    {/*<Grid item>*/}
                        {/*<Typography variant="h5">Analytics Report</Typography>*/}
                    {/*</Grid>*/}
                    {/*<Grid item />*/}
                {/*</Grid>*/}
                {/*<MainCard sx={{ mt: 2 }} content={false}>*/}
                    {/*<List sx={{ p: 0, '& .MuiListItemButton-root': { py: 2 } }}>*/}
                        {/*<ListItemButton divider>*/}
                            {/*<ListItemText primary="Company Finance Growth" />*/}
                            {/*<Typography variant="h5">+45.14%</Typography>*/}
                        {/*</ListItemButton>*/}
                        {/*<ListItemButton divider>*/}
                            {/*<ListItemText primary="Company Expenses Ratio" />*/}
                            {/*<Typography variant="h5">0.58%</Typography>*/}
                        {/*</ListItemButton>*/}
                        {/*<ListItemButton>*/}
                            {/*<ListItemText primary="Business Risk Cases" />*/}
                            {/*<Typography variant="h5">Low</Typography>*/}
                        {/*</ListItemButton>*/}
                    {/*</List>*/}
                    {/*<ReportAreaChart />*/}
                {/*</MainCard>*/}
            {/*</Grid>*/}

            {/* add customer dialog */}
            <Dialog
                maxWidth="sm"
                fullWidth
                TransitionComponent={PopupTransition}
                onClose={handleAdd}
                open={add}
                sx={{ '& .MuiDialog-paper': { p: 0 } }}
            >
                <AddWidget data={editDate} result={updateWidget} onCancel={handleAdd} />
            </Dialog>
        </Grid>
    );
};

export default DashboardDefault;

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// material-ui
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  InputLabel,
  Stack,
  Typography,
  TextField,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';

// project imports

import { openSnackbar } from 'store/reducers/snackbar';


// ==============================|| CUSTOMER ADD / EDIT / DELETE ||============================== //

const AddCustomer = ({ data, onCancel, result }) => {

  const dispatch = useDispatch();

  const getInitialValues = () => {
      let newCustomer = {
          name: '',
          title: ''
      };
      if(data){
          newCustomer = {
              name: data.name,
              title: data.title
          };
      }

      return newCustomer;
  };

  const CustomerSchema = Yup.object().shape({
    title: Yup.string().max(255).required('Заголовок віджету'),
    name: Yup.string().max(255).required('Пошукове слово'),
  });

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: CustomerSchema,
    onSubmit: (values, { setSubmitting }) => {
      try {
        const newCustomer = {
            title: values.title,
            name: values.name,
        };

        result(newCustomer);

        dispatch(
            openSnackbar({
                open: true,
                message: 'Віджет створенно',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );

        setSubmitting(false);
        onCancel();
      } catch (error) {
        console.error(error);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="customer-name">Заголовок віджету</InputLabel>
                        <TextField
                          fullWidth
                          id="customer-title"
                          placeholder="Заголовок віджету"
                          {...getFieldProps('title')}
                          error={Boolean(touched.title && errors.title)}
                          helperText={touched.title && errors.title}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={1.25}>
                            <InputLabel htmlFor="customer-name">Пошукове слово</InputLabel>
                            <TextField
                                fullWidth
                                id="customer-name"
                                placeholder="Пошукове слово"
                                {...getFieldProps('name')}
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                            />
                            <Typography variant="caption">Наступне слово писати через кому <b>','</b></Typography>
                        </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button color="error" onClick={onCancel}>
                      Відміна
                    </Button>
                    <Button type="submit" variant="contained" disabled={isSubmitting}>
                        {data ? 'Редагувати' :'Додати'}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </>
  );
};

AddCustomer.propTypes = {
  data: PropTypes.object,
  onCancel: PropTypes.func,
  result: PropTypes.func
};

export default AddCustomer;

// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  boards: [],
  brands: [],
  cancels: [],
  statuses: [],
  adses: [],
  columns: [],
  columnsOrder: [],
  comments: [],
  items: [],
  profiles: [],
  labels: [],
  selectedItem: false,
  userStory: [],
  userStoryOrder: []
};

const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // ADD COLUMN
    addColumnSuccess(state, action) {
      state.columns = action.payload.columns;
      state.columnsOrder = action.payload.columns;
    },

    // EDIT COLUMN
    editColumnSuccess(state, action) {
      state.columns = action.payload.columns;
    },

    // UPDATE COLUMN ORDER
    updateColumnOrderSuccess(state, action) {
      state.columnsOrder = action.payload.columnsOrder;
    },

    // DELETE COLUMN
    deleteColumnSuccess(state, action) {
      state.columns = action.payload.columns;
      state.columnsOrder = action.payload.columnsOrder;
    },

    // ADD ITEM
    addItemSuccess(state, action) {
      state.items = action.payload.items;
      state.columns = action.payload.columns;
      state.userStory = action.payload.userStory;
    },

    // EDIT ITEM
    editItemSuccess(state, action) {
      state.items = action.payload.items;
      state.columns = action.payload.columns;
      state.userStory = action.payload.userStory;
    },

    // UPDATE COLUMN ITEM ORDER
    updateColumnItemOrderSuccess(state, action) {
      state.columns = action.payload.columns;
    },

    // SELECT ITEM
    selectItemSuccess(state, action) {
      state.selectedItem = action.payload;
    },

    // ADD ITEM COMMENT
    addItemCommentSuccess(state, action) {
      state.items = action.payload.items;
      state.comments = action.payload.comments;
    },

    // DELETE ITEM
    deleteItemSuccess(state, action) {
      state.items = action.payload.items;
      state.columns = action.payload.columns;
      state.userStory = action.payload.userStory;
    },

    // ADD STORY
    addStorySuccess(state, action) {
      state.userStory = action.payload.userStory;
      state.userStoryOrder = action.payload.userStoryOrder;
    },

    // EDIT STORY
    editStorySuccess(state, action) {
      state.userStory = action.payload.userStory;
    },

    // UPDATE STORY ORDER
    updateStoryOrderSuccess(state, action) {
      state.userStoryOrder = action.payload.userStoryOrder;
    },

    // UPDATE STORY ITEM ORDER
    updateStoryItemOrderSuccess(state, action) {
      state.userStory = action.payload.userStory;
    },

    // ADD STORY COMMENT
    addStoryCommentSuccess(state, action) {
      state.userStory = action.payload.userStory;
      state.comments = action.payload.comments;
    },

    // DELETE STORY
    deleteStorySuccess(state, action) {
      state.userStory = action.payload.userStory;
      state.userStoryOrder = action.payload.userStoryOrder;
    },

    // GET brands
    getBrandsSuccess(state, action) {
      state.brands = action.payload;
    },

    // GET cancels
    getCancelsSuccess(state, action) {
      state.cancels = action.payload;
    },

    // GET statuses
    getStatusesSuccess(state, action) {
      state.statuses = action.payload;
    },

    // GET ads
    getAdsSuccess(state, action) {
      state.adses = action.payload;
    },

    // GET LABELS
    getLabelsSuccess(state, action) {
      state.labels = action.payload;
    },

    // GET BOARDS
    getBoardsSuccess(state, action) {
        state.boards = action.payload;
    },

    // GET COLUMNS
    getColumnsSuccess(state, action) {
      state.columns = action.payload;
    },

    // GET COLUMNS ORDER
    getColumnsOrderSuccess(state, action) {
      state.columnsOrder = action.payload;
    },

    // GET COMMENTS
    getCommentsSuccess(state, action) {
      state.comments = action.payload;
    },

    // GET PROFILES
    getProfilesSuccess(state, action) {
      state.profiles = action.payload;
    },

    // GET ITEMS
    getItemsSuccess(state, action) {
      state.items = action.payload;
    },

    // GET USER STORY
    getUserStorySuccess(state, action) {
      state.userStory = action.payload;
    },

    // GET USER STORY ORDER
    getUserStoryOrderSuccess(state, action) {
      state.userStoryOrder = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getBoards() {
    return async () => {
        try {
            const response = await axios.get('/boards');
            dispatch(slice.actions.getBoardsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getLabels() {
  return async () => {
    try {
      const response = await axios.get('/tasks/label');
      dispatch(slice.actions.getLabelsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCustom() {
  return async () => {
    try {
      const response = await axios.get('/tasks/custom');
      response.data.forEach((row) => {
        switch (row.name) {
          case 'Бренд':
            return dispatch(slice.actions.getBrandsSuccess(row.field));
          case 'Статус':
            return dispatch(slice.actions.getStatusesSuccess(row.field));
          case 'Джерело реклами':
            return dispatch(slice.actions.getAdsSuccess(row.field));
          case 'Причина відмови':
            return dispatch(slice.actions.getCancelsSuccess(row.field));
        }
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getColumns(boardId) {
  return async () => {
    try {
      const response = await axios.get(`/boards/sections/${boardId}`);
      dispatch(slice.actions.getColumnsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getColumnsOrder(boardId) {
  return async () => {
    try {
      const response = await axios.get(`/boards/sections/${boardId}`);
      dispatch(slice.actions.getColumnsOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getComments() {
  return async () => {
    try {
      const response = await axios.get('/api/kanban/comments');
      dispatch(slice.actions.getCommentsSuccess(response.data.comments));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProfiles() {
  return async () => {
    try {
      const response = await axios.get('/tasks/member');
      dispatch(slice.actions.getProfilesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getItems() {
  return async () => {
    try {
      const response = await axios.get('/api/kanban/items');
      dispatch(slice.actions.getItemsSuccess(response.data.items));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function addColumn(boardId, title) {
  return async () => {
    try {
      const responses = await axios.post(`/boards/${boardId}/sections`, title);
      const response = await axios.get(`/boards/sections/${boardId}`);
      dispatch(slice.actions.getColumnsOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editColumn(boardId, sectionId, title) {
  return async () => {
    try {
      const responses = await axios.put(`/boards/${boardId}/sections/${sectionId}`, title);
      const response = await axios.get(`/boards/sections/${boardId}`);
      dispatch(slice.actions.getColumnsOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateColumnOrder(boardId, params) {
  return async () => {
    try {
      const response = await axios.put(`/boards/${boardId}`, params);
      dispatch(slice.actions.getColumnsOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteColumn(boardId, sectionId) {
  return async () => {
    try {
      const responses = await axios.delete(`/boards/${boardId}/sections/${sectionId}`);
      const response = await axios.get(`/boards/sections/${boardId}`);
      dispatch(slice.actions.getColumnsOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addItem(boardId, params) {
  return async () => {
    try {
      const response = await axios.put(`boards/${boardId}/tasks`, params);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editItem(boardId, taskId, params) {
  return async () => {
    try {
      const response = await axios.put(`boards/${boardId}/tasks/${taskId}`,params);
      //dispatch(slice.actions.editItemSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateColumnItemOrder(boardId, params) {
  return async () => {
    try {
      const responses = await axios.put(`/boards/${boardId}/tasks/update-position`, params);
      // const response = await axios.get(`/boards/sections/${boardId}`);
      // dispatch(slice.actions.getColumnsOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function selectItem(taskId) {
  return async () => {
    try {
      if(taskId){
        const response = await axios.get(`tasks/one/${taskId}`);
        dispatch(slice.actions.selectItemSuccess(response.data));
        console.log(taskId)
      }else{
        console.log('false')
        dispatch(slice.actions.selectItemSuccess(false));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addItemComment(itemId, comment, items, comments) {
  return async () => {
    try {
      const response = await axios.post('/api/kanban/add-item-comment', { items, itemId, comment, comments });
      dispatch(slice.actions.addItemCommentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteItem(boardId, taskId) {
  return async () => {
    try {
      const response = await axios.post(`boards/${boardId}/tasks/${taskId}`);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserStory() {
  return async () => {
    try {
      const response = await axios.get('/api/kanban/userstory');
      dispatch(slice.actions.getUserStorySuccess(response.data.userStory));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserStoryOrder() {
  return async () => {
    try {
      const response = await axios.get('/api/kanban/userstory-order');
      dispatch(slice.actions.getUserStoryOrderSuccess(response.data.userStoryOrder));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addStory(story, userStory, userStoryOrder) {
  return async () => {
    try {
      const response = await axios.post('/api/kanban/add-story', { userStory, story, userStoryOrder });
      dispatch(slice.actions.addStorySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editStory(story, userStory) {
  return async () => {
    try {
      const response = await axios.post('/api/kanban/edit-story', { userStory, story });
      dispatch(slice.actions.editStorySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateStoryOrder(userStoryOrder) {
  return async () => {
    try {
      const response = await axios.post('/api/kanban/update-story-order', { userStoryOrder });
      dispatch(slice.actions.updateStoryOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateStoryItemOrder(userStory) {
  return async () => {
    try {
      const response = await axios.post('/api/kanban/update-storyitem-order', { userStory });
      dispatch(slice.actions.updateStoryItemOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addStoryComment(storyId, comment, comments, userStory) {
  return async () => {
    try {
      const response = await axios.post('/api/kanban/add-story-comment', { userStory, storyId, comment, comments });
      dispatch(slice.actions.addStoryCommentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteStory(storyId, userStory, userStoryOrder) {
  return async () => {
    try {
      const response = await axios.post('/api/kanban/delete-story', { userStory, storyId, userStoryOrder });
      dispatch(slice.actions.deleteStorySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    CloudUploadOutlined,
    FileDoneOutlined,
    FormOutlined,
    PieChartOutlined,
    StepForwardOutlined,
    TableOutlined,
    UserOutlined,
    InsertRowAboveOutlined, BuildOutlined
} from '@ant-design/icons';

// icons
const icons = {
  CloudUploadOutlined,
  FileDoneOutlined,
  FormOutlined,
  UserOutlined,
  PieChartOutlined,
  StepForwardOutlined,
  TableOutlined,
  InsertRowAboveOutlined
};

// ==============================|| MENU ITEMS - FORMS & TABLES ||============================== //

const formsTables = {
  id: 'all-pacient',
  title: <FormattedMessage id="all-pacient" />,
  icon: icons.FileDoneOutlined,
  type: 'group',
  url: '/pacient',
  children: [
    {
        id: 'all_p',
        title: <FormattedMessage id="all-pacient" />,
        type: 'item',
        url: '/pacient',
        icon: icons.TableOutlined
    },
    {
        id: 'all_work',
        title: <FormattedMessage id="pacient-work" />,
        type: 'item',
        url: '/all-work',
        icon: icons.TableOutlined
    },
    {
        id: 'all_rem',
        title: <FormattedMessage id="pacient-remind" />,
        type: 'item',
        url: '/reminder',
        icon: icons.TableOutlined
    },
    {
        id: 'all_lost',
        title: <FormattedMessage id="pacient-lost" />,
        type: 'item',
        url: '/ortoped',
        icon: icons.TableOutlined
    },
    {
        id: 'all_lost_cons',
        title: <FormattedMessage id="pacient-lost-cons" />,
        type: 'item',
        url: '/new',
        icon: icons.TableOutlined
    },
    {
        id: 'all_untre',
        title: <FormattedMessage id="pacient-untreated" />,
        type: 'item',
        url: '/new',
        icon: icons.TableOutlined
    },
    {
        id: 'all_calend',
        title: <FormattedMessage id="pacient-calendar" />,
        type: 'item',
        url: '/new',
        icon: icons.TableOutlined
    },
  ]
};

export default formsTables;

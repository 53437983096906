import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
// material-ui
import { Box, Chip, Grid, Stack, Typography, Tooltip } from '@mui/material';
import { openSnackbar } from 'store/reducers/snackbar';
// project import
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';


// assets
import { DeleteOutlined,SettingOutlined,FallOutlined, RiseOutlined } from '@ant-design/icons';
import AlertDelete from "./AlertDelete";

// ==============================|| STATISTICS - ECOMMERCE CARD  ||============================== //


const EcommerceWidget = ({ req,keys,days,field,handelFilter,edit }) => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const handleModalClose = (status) => {
        setOpen(false);
        if (status) {
            handelFilter(keys);
            dispatch(openSnackbar({
                open: true,
                message: 'Віджет видалено!',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            }))
        }
    };
    let links = field.name[0].split(','), all = '';
    let dates = req;
    if(links.length > 1){
        links.map((info,key) => {
            if(links.length-1 == key){
                all += `|${info}`
            }else{
                all += `poslugs=${info}`
            }
        });
    }else{
        all = `name=contains|${links[0]}`
    }

    let link = `/all-work?${all}&fcreate=${dates}`;
    return(
      <Grid item xs={6} sm={4} md={3} lg={3} key={keys}>
          <MainCard contentSX={{ p: 2.25 }}>
              <Stack spacing={0.5}>
                  <Typography variant="h6" color="textSecondary">
                      {field.name[1]}
                  </Typography>
                  <Tooltip title="Видалити віджет">
                      <IconButton
                          onClick={() => {
                              setOpen(true);
                          }}
                          style={{
                              fontSize: '1.15rem',
                              position:'absolute',
                              right:'15px',
                              cursor:'pointer'
                          }}
                          aria-controls="menu-simple-card" aria-haspopup="true" color="error">
                          <DeleteOutlined />
                      </IconButton>
                  </Tooltip>
                  <Tooltip title="Редагувати віджет">
                      <IconButton
                          onClick={() => {
                              console.log(keys);
                              edit(keys);
                          }}
                          style={{
                              fontSize: '1.15rem',
                              position:'absolute',
                              right:'50px',
                              cursor:'pointer'
                          }}
                          aria-controls="menu-simple-card" aria-haspopup="true">
                          <SettingOutlined />
                      </IconButton>
                  </Tooltip>
                  <AlertDelete title={field.name[1]} open={open} handleClose={handleModalClose} />
                  <Grid container alignItems="center">
                      <Grid item>
                          <Typography variant="h4" color="inherit">
                              <a href={link} target="_blank">{field.all.toLocaleString('uk-UA')}</a>
                          </Typography>
                      </Grid>
                      <Grid item>
                          <Chip
                              variant="combined"
                              color={field.count < 1 ? 'warning' : 'primary'}
                              icon={
                                <>
                                    {field.count > 1 && <RiseOutlined style={{ fontSize: '0.75rem', color: 'inherit' }} />}
                                    {field.count < 1 && <FallOutlined style={{ fontSize: '0.75rem', color: 'inherit' }} />}
                                </>
                              }
                              label={parseInt((field.count/field.all)*100)+'%'}
                              sx={{ ml: 1.25, pl: 1 }}
                              size="small"
                          />
                      </Grid>
                  </Grid>
              </Stack>
              <Box sx={{ pt: 2.25 }}>
                  <Typography variant="caption" color="textSecondary">
                      Ви зробили на{' '}
                      <Typography component="span" variant="caption" sx={{ color: `${field.count < '1' ? 'warning' : 'primary'}.main` }}>
                          {field.count < '1' ? parseInt(field.count*-1).toLocaleString('uk-UA') : parseInt(field.count).toLocaleString('uk-UA')} {field.count > '1' ? 'більше' : 'меньше'}
                      </Typography>{' '}
                      за {days}
                  </Typography>
              </Box>
          </MainCard>
      </Grid>
    );
};

EcommerceWidget.propTypes = {
    req: PropTypes.string,
    keys: PropTypes.number,
    field: PropTypes.object,
    days: PropTypes.string,
    handelFilter: PropTypes.func,
    edit: PropTypes.func
};

export default EcommerceWidget;

import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// project import
import MainLayout from 'layout/MainLayout';

import AuthGuard from 'utils/route-guard/AuthGuard';
import DashboardAnalytics from 'pages/dashboard/analytics';
//import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import ComponentsRoutes from './ComponentsRoutes';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';

// render - landing page
const PagesLanding = Loadable(lazy(() => import('pages/landing')));


// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    {
      // path: '/',
      // element: <CommonLayout layout="landing" />,
      // children: [
      //   {
      //     path: '/',
      //     element: <PagesLanding />
      //   }
      // ]
      path: '/',
      children: [
        {
          path: '/',
            element: (
                <AuthGuard>
                    <MainLayout />
                </AuthGuard>
            ),
            children: [
                {
                    path: '/',
                    element: (
                        <DashboardAnalytics />
                    )
                }
            ]
        }
      ]
    },
    LoginRoutes,
    ComponentsRoutes,
    MainRoutes
  ]);
}

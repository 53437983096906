// project import
import applications from './applications';
import widget from './widget';
import formsTables from './forms-tables';
import chartsMap from './charts-map';
//import other from './other';
import lids from './lids';
import pages from './pages';
import pacient from './pacient';
import reports from './reports';

import {UserOutlined, FileDoneOutlined, WindowsOutlined} from '@ant-design/icons';
//import { FormattedMessage } from 'react-intl';

// icons
const icons = {
    WindowsOutlined,
    UserOutlined,
    FileDoneOutlined
};

const homes = {
    id: 'home-page',
    title: 'Робочий стіл',
    type: 'item',
    url: '/dashboard/',
    icon: icons.WindowsOutlined
}

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [homes, lids, pacient, reports, widget, applications, formsTables, chartsMap, pages]
};

export default menuItems;

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    CloudUploadOutlined,
    FileDoneOutlined,
    FormOutlined,
    PieChartOutlined,
    StepForwardOutlined,
    TableOutlined,
    UserOutlined,
    InsertRowAboveOutlined, BuildOutlined
} from '@ant-design/icons';

// icons
const icons = {
  CloudUploadOutlined,
  FileDoneOutlined,
  FormOutlined,
  UserOutlined,
  PieChartOutlined,
  StepForwardOutlined,
  TableOutlined,
  InsertRowAboveOutlined
};

// ==============================|| MENU ITEMS - FORMS & TABLES ||============================== //

const formsTables = {
  id: 'all-tables',
  title: <FormattedMessage id="all-tables" />,
  icon: icons.FileDoneOutlined,
  type: 'group',
  children: [
    {
        id: 'people',
        title: <FormattedMessage id="lids-tables" />,
        type: 'item',
        url: '/lids',
        icon: icons.UserOutlined
    },
    {
        id: 'kanban',
        title: <FormattedMessage id="kanban" />,
        type: 'item',
        icon: BuildOutlined,
        url: 'boards'
    }
  ]
};

export default formsTables;

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Checkbox, FormControl, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import useConfig from 'hooks/useConfig';

// third-party
import ReactApexChart from 'react-apexcharts';
import IncomeAreaChart from "./default/IncomeAreaChart";

// chart options
const columnChartOptions = {
  chart: {
    type: 'bar',
    height: 430,
    toolbar: {
      show: false
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '30%',
      borderRadius: 4
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    show: true,
    width: 8,
    colors: ['transparent']
  },
  fill: {
    opacity: 1
  },
  legend: {
    show: false
  },
  responsive: [
    {
      breakpoint: 600,
      options: {
        yaxis: {
          show: false
        }
      }
    }
  ]
};

// ==============================|| SALES COLUMN CHART ||============================== //

const SalesChart = ({slot, patient}) => {
  const theme = useTheme();
  const { mode } = useConfig();

  const [legend, setLegend] = useState({
    income: true,
    cos: true
  });
  const { income, cos } = legend;

  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const warning = theme.palette.warning.main;
  const primaryMain = theme.palette.primary.main;
  const successDark = theme.palette.success.dark;

  const initialSeries = [
    {
      name: 'Нові пацієнти',
      data: patient.lids
    },
    {
      name: 'Початок лікування',
      data: patient.paying
    }
  ];

  const [series, setSeries] = useState([]);

  const handleLegendChange = (event) => {
    setLegend({ ...legend, [event.target.name]: event.target.checked });
  };

  function subtractMonths(months) {
      let D = new Date();
      D.setMonth(D.getMonth() - months);
      const monthNames = ['Cіч', 'Лют', 'Бер', 'Квіт', 'Трав', 'Черв', 'Лип', 'Серп', 'Вер', 'Жовт', 'Лист', 'Груд'];
      return monthNames[D.getMonth()];
  }

  const categoriesChange = (event) => {
      let three = [], six = [];
      for (let i = 5; i > -1; i--) {
        six.push(subtractMonths(+i)+' 1-15');
        six.push(subtractMonths(+i)+' 15-31');
      }
      for (let i = 2; i > -1; i--) {
        three.push(subtractMonths(+i)+' (1-10)');
        three.push(subtractMonths(+i)+' (10-20)');
        three.push(subtractMonths(+i)+' (20-31)');
      }
      switch (event) {
          case 6:
              return ['Понеділок', 'Вівторок', 'Середа', 'Четверг', 'П`ятниця', 'Субота', 'Неділя'];
          case 14:
              return ['1-2', '3-4', '5-6', '7-8', '9-10', '11-12', '13-14', '15-16', '17-18', '19-20', '21-22', '23-24', '25-26', '27-28', '29-30-31'];
          case 9:
              return three;
          case 11:
              return six;
          case 12:
              return ['Cіч', 'Лют', 'Бер', 'Квіт', 'Трав', 'Черв', 'Лип', 'Серп', 'Вер', 'Жовт', 'Лист', 'Груд'];
          default:
              return ['Понеділок', 'Вівторок', 'Середа', 'Четверг', 'П`ятниця', 'Субота', 'Неділя'];
      }
  };

  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [options, setOptions] = useState(columnChartOptions);

  useEffect(() => {
    if (income && cos) {
      setSeries(initialSeries)
    } else if (income) {
      setSeries([
        {
          name: 'Нові пацієнти',
          data: patient.lids
        }
      ]);
    } else if (cos) {
      setSeries([
        {
          name: 'Початок лікування',
          data: patient.paying
        }
      ]);
    } else {
      setSeries([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [income, cos]);

  useEffect(() => {
      setSeries(initialSeries);
  }, [slot]);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: !(income && cos) && cos ? [primaryMain] : [warning, primaryMain],
      xaxis: {
        categories: categoriesChange(slot),
        labels: {
          style: {
            colors: [secondary, secondary, secondary, secondary, secondary, secondary]
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: [secondary]
          }
        }
      },
      grid: {
        borderColor: line
      },
      tooltip: {
          y: {
              formatter(val, opts) {
                  return opts.seriesIndex == '0' ? `${val}` : `${val} (сума: ${patient.pay[opts.dataPointIndex].toLocaleString('uk-UA')} грн)`;
              }
          },
        theme: mode === 'dark' ? 'dark' : 'light'
      },
      plotOptions: {
        bar: {
          columnWidth: xsDown ? '60%' : '30%'
        }
      }
    }));
  }, [mode, primary, secondary, line, warning, primaryMain, successDark, income, cos, xsDown, slot]);

  return (
    <MainCard sx={{ mt: 1 }} content={false}>
      <Box sx={{ p: 2.5, pb: 0 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack spacing={1.5}>
            <Typography variant="h6" color="secondary">
              Зароблено
            </Typography>
            <Typography variant="h4">{patient.total > 0 ? patient.total.toLocaleString('uk-UA') : '0'} грн</Typography>
          </Stack>
          <FormControl component="fieldset">
            <FormGroup row>
              <FormControlLabel
                control={<Checkbox color="warning" checked={income} onChange={handleLegendChange} name="income" />}
                label="Нові пацієнти"
              />
              <FormControlLabel control={<Checkbox checked={cos} onChange={handleLegendChange} name="cos" />} label="Початок лікування" />
            </FormGroup>
          </FormControl>
        </Stack>
        <div id="chart">
          <ReactApexChart options={options} series={series} type="bar" height={360} />
        </div>
      </Box>
    </MainCard>
  );
};

IncomeAreaChart.propTypes = {
    slot: PropTypes.string,
    patient: PropTypes.object,
};

export default SalesChart;

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// material-ui
import { useTheme } from '@mui/material/styles';
import './app.css';
// third-party
import ReactApexChart from 'react-apexcharts';

// project import
import useConfig from 'hooks/useConfig';

// chart options
const pieChartOptions = {
  chart: {
    type: 'pie',
    width: 500,
    height: 500,
      offsetX: 0
  },
  legend: {
    show: true,
    fontFamily: `'Roboto', sans-serif`,
    offsetX: 0,
    offsetY: 0,
    labels: {
      useSeriesColors: false
    },
    markers: {
      width: 12,
      height: 12,
      radius: 5
    },
    itemMargin: {
      horizontal: 0,
      vertical: 0
    }
  },
  responsive: [
    {
      breakpoint: 450,
      chart: {
        width: 280,
        height: 280
      },
      options: {
        legend: {
          show: false,
          position: 'bottom'
        }
      }
    }
  ]
};

// ==============================|| APEXCHART - PIE ||============================== //

const ApexPieChart = ({data}) => {
  const theme = useTheme();
  const { mode } = useConfig();

  const { primary } = theme.palette.text;
  const line = theme.palette.divider;
  const grey200 = theme.palette.grey[200];
  const backColor = theme.palette.background.paper;

  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState(pieChartOptions);

  const secondary = theme.palette.primary[700];
  const primaryMain = theme.palette.primary.main;
  const successDark = theme.palette.success.main;
  const error = theme.palette.error.main;
  const orangeDark = theme.palette.warning.main;

  useEffect(() => {
    let search = [], paidItem = [];
    const monthNames = ['Cіч', 'Лют', 'Бер', 'Квіт', 'Трав', 'Черв', 'Лип', 'Серп', 'Вер', 'Жовт', 'Лист', 'Груд'];
    data.forEach(pay => {
        paidItem.push(parseInt(pay.count));
        search.push(monthNames[pay.month]+'-'+pay.year);
    });
    setSeries(paidItem);
    setOptions((prevState) => ({
      ...prevState,
      colors: [secondary, primaryMain, successDark, error, orangeDark],
      xaxis: {
        labels: {
          style: {
            colors: [primary, primary, primary, primary, primary, primary, primary]
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: [primary]
          }
        }
      },
      grid: {
        borderColor: line
      },
      tooltip: {
          y: {
              formatter(val, opts) {
                  return opts.seriesIndex == '0' ? `${val}` : `${data[opts.dataPointIndex].count.toLocaleString('uk-UA')} грн `;
              }
          },
      },
      labels: search,
      legend: {
        labels: {
          colors: 'grey.500'
        }
      },
      stroke: {
        colors: [backColor]
      }
    }));
  }, [primary, line, grey200, backColor, secondary, primaryMain, successDark, error, orangeDark, data]);

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="pie" />
    </div>
  );
};

ApexPieChart.propTypes = {
    data: PropTypes.array,
};

export default ApexPieChart;

import { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import {Box, ButtonBase, FormControl, Grid, TextField, InputLabel, MenuItem, Select, Stack, Paper, ClickAwayListener, CardContent, Popper, Typography} from '@mui/material';

// project import
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';

// assets
import {
    CalendarOutlined,
} from '@ant-design/icons';

const names = [
    { name: "Сьогодні", code: 0 },
    { name: "Вчора", code: 1 },
    { name: "Тиждень", code: 7 },
    { name: "Місяць", code: 30 },
    { name: "Квартал", code: 90 },
    { name: "Рік", code: 365 },
    { name: "Останні 14 днів", code: 14 },
    { name: "Останні 60 днів", code: 60 },
    { name: "Останні 180 днів", code: 180 },
    { name: "Весь час", code: 99999 },
    { name: "Обрана дата", code: 'no'}
];

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Date = ({updateTable, all}) => {
    const today = moment();
    const theme = useTheme();
    const color = theme.palette.success.dark;
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [days, setDays] = useState(all == 'no' ? names[5] : names[0]);
    const [day, setDay] = useState(all == 'no' ? {start: today.clone().startOf('year').format('YYYY-MM-DD'),end: today.clone().endOf('year').format('YYYY-MM-DD')} : {start: today.format('YYYY-MM-DD'),end: today.format('YYYY-MM-DD')});

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleChange = (event) => {
        const todays = moment();
        const { value } = event.target;
        let start = '', end = '';

        switch (value.code) {
            case 0:
                start = todays;
                end = todays;
                break;
            case 1:
                start = todays.clone().add(-1, 'day');
                end = start;
                break;
            case 7:
                start = todays.clone().startOf('isoWeek');
                end = todays.clone().endOf('isoWeek');
                break;
            case 30:
                start = todays.clone().startOf('month');
                end = todays.clone().endOf('month');
                break;
            case 90:
                start = todays.clone().startOf('month').add(-2, 'month');
                end = todays.clone().endOf('month');
                break;
            case 365:
                start = todays.clone().startOf('year');
                end = todays.clone().endOf('year');
                break;
            default:
                start = todays.clone().add(-parseInt(value.code), 'day');
                end = todays;
                break;
        }

        setDay({ start:start.format('YYYY-MM-DD'), end:end.format('YYYY-MM-DD') });
        setDays({ name: value.name, code: value.code });
    };

    useEffect(() => {
        const getDay = async () => {
            try {
                updateTable(day,days.name)
            } catch (err) {
                alert(err)
            }
        };
        getDay()
    }, [day]);

    return (
        <Box sx={{ flexShrink: 0 }}>
            <Grid container spacing={3} >
                <Grid item sx={all == 'no' ? {margin: '0 0 10px auto'} : {margin: '-30px 0 20px auto'}}>
                    <MainCard sx={{overflow: 'visible'}}>
                        <Grid container justifyContent="space-between" alignItems="center" sx={all == 'no' ? { minWidth: 200} :{ minWidth: 290}}>
                            { all != 'no' && (<Grid item>
                                <Stack spacing={1}>
                                    <Typography variant="h4">{all}</Typography>
                                </Stack>
                            </Grid>) }
                            <FormControl sx={{ margin: '10px 15px 10px auto', width: 150 }}>
                                <InputLabel id="demo-simple-select-label">Дата</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="standard-select-currency"
                                    value={days}
                                    label="Дата"
                                    renderValue={({ name }) => name}
                                    onChange={handleChange}
                                >
                                    {names.map(l => (
                                        <MenuItem value={l} key={l.code}>
                                            {l.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <ButtonBase
                                sx={{
                                    p: 0.25,
                                    borderRadius: 1,
                                    '&:hover': { bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter' },
                                    '&:focus-visible': {
                                        outline: `2px solid ${theme.palette.secondary.dark}`,
                                        outlineOffset: 2
                                    }
                                }}
                                aria-label="open profile"
                                ref={anchorRef}
                                aria-controls={open ? 'profile-grow' : undefined}
                                aria-haspopup="true"
                                onClick={handleToggle}>
                                <Typography
                                    variant="h2"
                                    style={{color}}>
                                    <CalendarOutlined fontSize="large" />
                                </Typography>
                            </ButtonBase>
                        </Grid>
                        <Popper
                            placement="bottom-end"
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal
                            sx={{zIndex: 1}}
                        >
                            {({ TransitionProps }) => (
                                <Transitions type="grow" position="top-right" in={open} {...TransitionProps}>
                                    <Paper
                                        sx={{
                                            boxShadow: theme.customShadows.z1,
                                            width: 290,
                                            minWidth: 240,
                                            maxWidth: 290,
                                            [theme.breakpoints.down('md')]: {
                                                maxWidth: 250
                                            }
                                        }}
                                    >
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MainCard elevation={0} border={false} content={false}>
                                                <CardContent sx={{ px: 2.5, pt: 3 }}>
                                                    <Grid container justifyContent="space-between" alignItems="center">
                                                        <Stack spacing={1}>
                                                            <InputLabel>Старт</InputLabel>
                                                            <FormControl sx={{ width: '100%' }}>
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <DatePicker
                                                                        inputFormat="yyyy-MM-dd"
                                                                        value={day.start}
                                                                        onChange={
                                                                            (newValue) => {
                                                                                setDays({ name: moment(day.end).diff(moment(newValue), 'days')+" днів", code: moment(day.end).diff(moment(newValue), 'days')})
                                                                                setDay(day => ({
                                                                                    ...day,
                                                                                    start: moment(newValue).format('YYYY-MM-DD')
                                                                                }))
                                                                            }
                                                                        }
                                                                        renderInput={(params) => <TextField {...params} />}
                                                                    />
                                                                </LocalizationProvider>
                                                            </FormControl>
                                                            <InputLabel>Кінець</InputLabel>
                                                            <FormControl sx={{ width: '100%' }}>
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <DatePicker
                                                                        inputFormat="yyyy-MM-dd"
                                                                        value={day.end}
                                                                        onChange={
                                                                            (newValue) => {
                                                                                setDays({ name: moment(day.end).diff(moment(newValue), 'days')+" днів", code: moment(newValue).diff(moment(day.start), 'days')})
                                                                                setDay(day => ({
                                                                                    ...day,
                                                                                    end: moment(newValue).format('YYYY-MM-DD')
                                                                                }))
                                                                            }
                                                                        }
                                                                        renderInput={(params) => <TextField {...params} />}
                                                                    />
                                                                </LocalizationProvider>
                                                            </FormControl>
                                                        </Stack>
                                                    </Grid>
                                                </CardContent>
                                            </MainCard>
                                        </ClickAwayListener>
                                    </Paper>
                                </Transitions>
                            )}
                        </Popper>
                    </MainCard>
                </Grid>
            </Grid>
        </Box>
    );
};

Date.propTypes = {
    updateTable: PropTypes.func,
    all: PropTypes.number
};

export default Date;
